import { componentStyleWithExtend } from '@/styling/util/component-style.ts';

export const accordionStyle: ReturnType<typeof componentStyleWithExtend> = componentStyleWithExtend({
    extend: {
        root: {
            panelBorderColorActive: '{primary.color}',
        },
    },
    style: {
        headerToggleIconColor: '{primary.color}',
        headerToggleIconActiveColor: '{primary.color}',
        panelBorderWidth: '1px',
        headerColor: '{color.text}',
    },
    css: ({ dt }) => `
        .p-accordionpanel.p-accordionpanel-active {
            border-color: ${dt('accordion.panel.border.color.active')};
        }
    `,
});
