import type { ToastMessageOptions } from 'primevue/toast';
import { i18n } from '@/i18n/i18n';
// eslint-disable-next-line import-x/no-unresolved
import toasteventbus from 'primevue/toasteventbus';

export const GLOBAL_TOAST_GROUP_NAME = 'GLOBAL_TOAST';

export const TOAST_LIFE_TIME = 3000;

export type MessageValues = Record<string, string>;

interface ToastMessageOptionsWithValues extends ToastMessageOptions {
    summaryValues?: MessageValues;
    detailValues?: MessageValues;
}

interface UseGlobalToastReturn {
    defaultToastOptions: ToastMessageOptionsWithValues;
    errorToastOptions: ToastMessageOptionsWithValues;
    addToast: (options: ToastMessageOptionsWithValues) => void;
}

export default function useGlobalToast(): UseGlobalToastReturn {
    const t = i18n.global.t;

    const defaultToastOptions: ToastMessageOptionsWithValues = {
        life: TOAST_LIFE_TIME,
        group: GLOBAL_TOAST_GROUP_NAME,
    };

    const errorToastOptions: ToastMessageOptionsWithValues = {
        ...defaultToastOptions,
        severity: 'error',
        summary: 'ERROR.DEFAULT_SUMMARY',
        detail: 'ERROR.DEFAULT_DETAIL',
    };

    // allows us to add toasts outside of vue component
    function addToast(options: ToastMessageOptionsWithValues): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        toasteventbus.emit('add', {
            ...options,
            summary: t(options.summary ?? '', options.summaryValues ?? {}),
            detail: t(options.detail ?? '', options.detailValues ?? {}),
        });
    }

    return { defaultToastOptions, errorToastOptions, addToast };
}
