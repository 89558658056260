import { componentStyle } from '@/styling/util/component-style.ts';

export const buttonStyle = componentStyle({
    outlinedPrimaryBorderColor: 'surface.0',
    paddingX: '1rem',
    labelFontWeight: '400',
    // primary
    primaryHoverBackground: '{ primary.600 }',
    primaryHoverBorderColor: '{ primary.600 }',
    primaryActiveBackground: '{ primary.600 }',
    primaryActiveBorderColor: '{ primary.600 }',
    textPrimaryHoverBackground: '{primary.950 }',
    outlinedPrimaryHoverBackground: '{primary.950 }',
    // secondary
    secondaryHoverBackground: '{ color.buttonSecondaryHoverBackground }',
    secondaryHoverBorderColor: '{ color.buttonSecondaryHoverBackground }',
    secondaryHoverColor: '{ color.secondaryBlue }',
    secondaryBackground: '{ color.backgroundLightblue }',
    secondaryBorderColor: '{ color.backgroundLightblue }',
    secondaryColor: '{ color.secondaryBlue }',
});

export const buttonFontWeight500 = componentStyle({
    labelFontWeight: '500',
});
