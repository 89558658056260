export const fontSizeTokens = {
    1: '0.75rem', // 12px
    2: '0.875rem', // 14px
    3: '1rem', // 16px
    4: '1.125rem', // 18px
    5: '1.25rem', // 20px
    6: '1.5rem', // 24px
    7: '1.875rem', // 30px
    8: '2.25rem', // 36px
    9: '3rem', // 48px
};
