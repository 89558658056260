import { componentStyleWithExtend } from '@/styling/util/component-style.ts';

export const panelStyle: ReturnType<typeof componentStyleWithExtend> = componentStyleWithExtend({
    extend: {
        root: {
            headerBorderBottom: '1px solid {content.border.color}',
        },
    },
    style: {
        headerBackground: '{primitive.gray.50}',
        contentPadding: '1rem',
    },
    css: ({ dt }) => `
        .p-panel-header {
            border-bottom: ${dt('panel.header.border.bottom')};
        }
    `,
});
