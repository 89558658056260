import { componentStyleWithExtend } from '@/styling/util/component-style.ts';

export const menuStyle: ReturnType<typeof componentStyleWithExtend> = componentStyleWithExtend({
    extend: {
        handle: {
            padding: '0',
        },
    },
    style: {
        borderRadius: '4px',
    },
    css: ({ dt }) => `
        .p-menu {
            padding: ${dt('menu.padding')};
        }
    `,
});
