import { componentStyle } from '@/styling/util/component-style.ts';

export const stepperStyle = componentStyle({
    stepTitleColor: '{color.text}',
    stepTitleFontWeight: '400',
    stepTitleActiveColor: '{color.text}',
    stepNumberBackground: '{ color.backgroundLightblue }',
    stepNumberActiveBackground: '{ color.backgroundLightblue }',
    stepNumberBorderColor: '{ color.borderLightGray }',
    stepNumberActiveBorderColor: '{ color.borderLightGray }',
    stepNumberColor: '#00529e',
    stepNumberActiveColor: '#00529e',
    separatorActiveBackground: '#bcc3cd',
    separatorBackground: '#bcc3cd',
});
