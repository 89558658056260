export const breakpoints = {
    xs: 0, // Extra Small Devices (phones, less than 576px)
    sm: 576, // Small Devices (phones, 576px and up)
    md: 768, // Medium Devices (tablets, 768px and up)
    lg: 992, // Large Devices (desktops, 992px and up)
    xl: 1200, // Extra Large Devices (large desktops, 1200px and up)
    xxl: 1440, // Extra Extra Large Devices (larger desktops, 1440px and up
};

export const breakpointTokens = {
    breakpointXs: breakpoints.xs,
    breakpointSm: breakpoints.sm,
    breakpointMd: breakpoints.md,
    breakpointLg: breakpoints.lg,
    breakpointXl: breakpoints.xl,
    breakpointXxl: breakpoints.xxl,
};
