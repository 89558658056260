export const spacingTokens = {
    1: '2px',
    2: '4px',
    3: '8px',
    4: '12px',
    5: '16px',
    6: '24px',
    7: '32px',
    8: '64px',
    9: '96px',
    10: '128px',
    11: '192px',
    12: '256px',
    13: '384px',
    14: '512px',
    15: '640px',
    16: '768px',
};
