import { componentStyleWithExtend } from '@/styling/util/component-style.ts';

export const datePickerStyle: ReturnType<typeof componentStyleWithExtend> = componentStyleWithExtend({
    extend: {},
    style: {},
    css: () => `
        .p-datepicker-select-month, .p-datepicker-select-year {
            font-family: inherit;
        }
    `,
});
