interface Style {
    style: Record<string, string>;
    extend?: ExtendContext;
    css?(context: CSSContext): string;
}

interface ExtendContext {
    root?: Record<string, string>;
    handle?: Record<string, string>;
}

interface CSSContext {
    dt(this: void, styles: string): string;
}

interface Output {
    colorScheme: {
        light: Record<string, string>;
    };
    extend?: ExtendContext;
    css(context: CSSContext): string | undefined;
}

export function componentStyle(style: Record<string, string>): object {
    return {
        colorScheme: {
            light: style,
        },
    };
}

export function componentStyleWithExtend(style: Style): Output {
    return {
        colorScheme: {
            light: style.style,
        },
        extend: style.extend,
        css: (context) => style.css?.(context),
    };
}
