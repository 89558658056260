<script setup lang="ts">
    import Drawer from 'primevue/drawer';
    import Button from 'primevue/button';
    import router from '@/router';
    import { isMobile } from '@/util/breakpoints';
    import { useCartSidebarAction, useCartSidebarQuery } from '@/composables/cart-sidebar';
    import { useI18n } from 'vue-i18n';
    import { getComputedCartUrlByProvisionType } from '@/util/router';
    import { componentStyle } from '@/styling/util/component-style';

    const { t } = useI18n();
    const { isVisible, lastLineItemName } = useCartSidebarQuery();
    const cartSidebarAction = useCartSidebarAction();

    const cartUrl = getComputedCartUrlByProvisionType();

    function handleClose(): void {
        cartSidebarAction.setIsVisible(false);
    }

    const buttonStyle = componentStyle({
        labelFontWeight: '600',
    });
</script>

<template>
    <Drawer
        v-model:visible="isVisible"
        :position="isMobile ? 'full' : 'right'"
        :block-scroll="true"
        :style="{ width: '360px', border: 'none' }"
    >
        <template #container>
            <div class="header-container">
                <div class="close-button-container">
                    <Button
                        icon="pi pi-times"
                        text
                        rounded
                        class="close-button"
                        severity="secondary"
                        @click="handleClose"
                    />
                </div>
                <div class="info-container">
                    <div class="info-container-icon"><i class="pi pi-check"></i></div>
                    <div>
                        <div class="info-container-title">{{ t('CART.SIDEBAR.TITLE') }}</div>
                        <div class="info-container-description">
                            {{ t('CART.SIDEBAR.DESCRIPTION', { value: lastLineItemName }) }}
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <Button
                        v-if="cartUrl != null"
                        class="cart-button"
                        :label="t('CART.SIDEBAR.CART_BUTTON')"
                        data-testid="cart-sidebar-cart-button"
                        :dt="buttonStyle"
                        @click="
                            () => {
                                handleClose();
                                router.push({ name: cartUrl });
                            }
                        "
                    />
                    <Button
                        class="continue-button"
                        outlined
                        :label="t('CART.SIDEBAR.CONTINUE_SHOPPING_BUTTON')"
                        data-testid="cart-sidebar-close-button"
                        :dt="buttonStyle"
                        @click="handleClose"
                    />
                </div>
            </div>
        </template>
    </Drawer>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .close-button-container {
        text-align: right;
        padding-bottom: 8px;
    }

    .close-button {
        color: main.$color-white;

        &:hover {
            background-color: main.$color-primary-600;
        }
    }

    .info-container {
        display: flex;
        gap: main.$spacing-6;
        padding: 0 8px 20px;

        &-icon {
            padding-top: 8px;

            i {
                font-size: main.$font-size-6;
            }
        }

        &-title {
            font-weight: 600;
            font-size: main.$font-size-4;
        }

        &-description {
            line-height: 120%;
        }
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
    }

    .cart-button {
        background-color: main.$color-white;
        color: main.$color-primary-500;
        font-weight: 600;

        &:hover {
            background-color: main.$color-background-lightblue;
        }
    }

    .continue-button {
        color: main.$color-white;
        font-weight: 500;

        &:hover {
            background-color: main.$color-primary-600;
        }
    }

    .header-container {
        background-color: main.$color-primary-500;
        color: main.$color-white;
        padding: 12px 16px 24px;
    }
</style>
