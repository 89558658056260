<script setup lang="ts">
    import { computed } from 'vue';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';

    const stageName = computed(() => {
        const currentLocation = window.location.hostname;

        switch (currentLocation) {
            case 'dev-partnerportal.aws.containex.com':
            case 'portal.dev.containex.com':
                return 'dev';
            case 'test-partnerportal.aws.containex.com':
            case 'portal.test.containex.com':
                return 'staging';
            default:
                return undefined;
        }
    });

    async function navigateToChangelog(): Promise<void> {
        await router.push({ name: Navigation.Changelog });
    }
</script>

<template>
    <div v-if="stageName != null" class="stage-badge" :class="stageName" aria-hidden="true">
        <a class="content" @click="navigateToChangelog">{{ stageName }}</a>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .stage-badge {
        position: fixed;
        left: 0;
        bottom: 0;

        width: 10ch;
        padding: 0 main.$spacing-5;

        text-align: center;
        cursor: pointer;
    }

    .dev {
        background-color: #f97316;
    }

    .staging {
        background-color: #0ea5e9;
    }

    .content {
        text-decoration: none;
        color: main.$color-white;
        font-weight: bold;
    }
</style>
