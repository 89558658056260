import { componentStyleWithExtend } from '@/styling/util/component-style.ts';

export const cardStyle: ReturnType<typeof componentStyleWithExtend> = componentStyleWithExtend({
    extend: {
        root: {
            border: '1px solid {content.border.color}',
        },
    },
    style: {
        shadow: '0',
        borderRadius: '{border.radius.sm}',
        bodyPadding: '1rem',
    },
    css: ({ dt }) => `
        .p-component.p-card {
            border: ${dt('card.border')};
        }
        
        .p-card-title {
            margin-bottom: .5rem;
        }
    `,
});
